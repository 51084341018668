<template>
  <v-main>
  <v-container id="htu-main">
    <v-row>
      <v-card flat>
        <v-card-title>LINE Edition</v-card-title>
        <v-card-text>
          <v-btn text color="primary" @click="$router.push('/how_to_line')">こちらをクリック</v-btn>
        </v-card-text>
      </v-card>
    </v-row>
  </v-container>
  </v-main>
</template>

<script>
export default {
  name: 'HowToUse',
  components: {
  },
}
</script>

<style lang="css">
#htu-main {
  margin-bottom: 10vh;
}
</style>
